.container {
 /* background-image: url('../assets/landing.jpg');*/
  background-color: #202525;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 100vh;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.title {
  align-self: center; 
  margin-top: 10px; 
  color: white;
  font-size: 35px; 
  margin-bottom: 5; 
  font-family: Bevan;
}

.subtitle {
  font-weight: 400;
  align-self: center;
  font-family: Arial;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  max-width: 80%;
  white-space: pre-line;
  flex-wrap: wrap;
  text-align: center;
}

.topContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 40px;
}

.lottieContainer {
  width: 300px; 
  height: 100%; 
  margin-bottom: 120px
}

.input {
  margin-left: 20px;
  padding: 20px; 
  border-radius: 10px;
  align-items: center; 
  justify-content: center;
}

.details {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.signUp {
  font-weight: bold; 
  font-size: 25px; 
  color: white;
  margin-bottom: 5px;
  font-family: Arial;
}

.signUpSubtitle {
  font-weight: 300; 
  font-size: 15px;
  margin-bottom: 10px;
  color: white;
  color: #15A97C;
  font-family: Arial;
}

.row {
  display: flex;
  flex-direction: row;
}

.video {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  border-radius: 10px;
  margin-left:  40px;
/*  background-color: #fefbe9;*/
  boxShadow: "1px 3px 1px #9E9E9E"
}

.users {
  display: flex;
  flex-direction: column;
  margin-left:  30px;
  min-width: 200px
}

.footer {
  width:  20%;
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-left: 70px;
}

.team {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.member {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.bottomSection {
  width:  100%;
  align-self: center;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.item {
  display: flex;
  flex-direction: column;
}