.expired-title {
  align-self: center; 
  margin-top: 10px; 
  color: white;
  font-size: 18px; 
  font-family: 'sans-serif;';
  font-weight:  600;
  display: 'flex';
  overflow-x: auto;
  white-space: pre-wrap;
  text-align: center;
  width: 80%;
  margin-bottom: 10px;
}

.expired-subtitle {
  display: 'flex';
  font-weight: 400;
  align-self: center;
  color: white;
  font-size: 14px;
  max-width: 80%;
  white-space: pre-line;
  flex-wrap: wrap;
  text-align: center;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  color: #15A97C;
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  color:  white;
}